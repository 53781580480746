exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-html-review-request-emails-js": () => import("./../../../src/pages/html-review-request-emails.js" /* webpackChunkName: "component---src-pages-html-review-request-emails-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plain-text-review-request-emails-js": () => import("./../../../src/pages/plain-text-review-request-emails.js" /* webpackChunkName: "component---src-pages-plain-text-review-request-emails-js" */),
  "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-five-ways-to-leverage-reviews-mdx": () => import("./../../../src/templates/Article.js?__contentFilePath=/Users/garretcassels/repo/review-request-email/src/articles/five-ways-to-leverage-reviews.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-five-ways-to-leverage-reviews-mdx" */),
  "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-html-vs-plain-text-emails-mdx": () => import("./../../../src/templates/Article.js?__contentFilePath=/Users/garretcassels/repo/review-request-email/src/articles/html-vs-plain-text-emails.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-html-vs-plain-text-emails-mdx" */),
  "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-review-request-email-templates-mdx": () => import("./../../../src/templates/Article.js?__contentFilePath=/Users/garretcassels/repo/review-request-email/src/articles/review-request-email-templates.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-review-request-email-templates-mdx" */),
  "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-subject-lines-for-review-request-emails-mdx": () => import("./../../../src/templates/Article.js?__contentFilePath=/Users/garretcassels/repo/review-request-email/src/articles/subject-lines-for-review-request-emails.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-subject-lines-for-review-request-emails-mdx" */),
  "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-ten-review-requests-mistakes-to-avoid-mdx": () => import("./../../../src/templates/Article.js?__contentFilePath=/Users/garretcassels/repo/review-request-email/src/articles/ten-review-requests-mistakes-to-avoid.mdx" /* webpackChunkName: "component---src-templates-article-js-content-file-path-users-garretcassels-repo-review-request-email-src-articles-ten-review-requests-mistakes-to-avoid-mdx" */),
  "component---src-templates-email-template-page-js": () => import("./../../../src/templates/EmailTemplatePage.js" /* webpackChunkName: "component---src-templates-email-template-page-js" */)
}

